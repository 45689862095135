// Проверка поддержки webp, добавление класса webp или no-webp для HTML

function testWebP(callback) {
	let webP = new Image()
	webP.onload = webP.onerror = function () {
		callback(webP.height == 2)
	}
	webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
}
testWebP(function (support) {
	let className = support === true ? 'webp' : 'no-webp'
	document.documentElement.classList.add(className)
})

// ОТКЛЮЧИТЬ РАМКУ :FOCUS ПО КЛИКУ, НО ПОКАЗЫВАТЬ ПРИ ТАББАНГЕ С КЛАВИАТУРЫ

function handleFirstTab(e) {
	if (e.keyCode === 9) {
		document.body.classList.add('user-is-tabbing')
		window.removeEventListener('keydown', handleFirstTab)
		window.addEventListener('mousedown', handleMouseDownOnce)
	}
}
function handleMouseDownOnce() {
	document.body.classList.remove('user-is-tabbing')
	window.removeEventListener('mousedown', handleMouseDownOnce)
	window.addEventListener('keydown', handleFirstTab)
}
window.addEventListener('keydown', handleFirstTab)

// КНОПКА СКРОЛЛА НАВЕРХ

const scrollBtn = document.querySelector('.scroll-to-top')
const btnVisibility = () => {
	if (window.scrollY > 400) {
		scrollBtn.style.visibility = 'visible'
	} else {
		scrollBtn.style.visibility = 'hidden'
	}
}
document.addEventListener('scroll', () => {
	btnVisibility()
})
scrollBtn.addEventListener('click', () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
})

// ПЛАВНЫЙ ДРОПДАУН В ВЕРТИКАЛЬНОМ МЕНЮ

document.addEventListener('DOMContentLoaded', function () {
	document.querySelectorAll('.sidebar .nav-link').forEach(function (element) {
		element.addEventListener('click', function (e) {
			element.classList.toggle('active')

			let nextEl = element.nextElementSibling
			let parentEl = element.parentElement

			if (nextEl) {
				e.preventDefault()
				let mycollapse = new bootstrap.Collapse(nextEl)

				if (nextEl.classList.contains('show')) {
					mycollapse.hide()
				} else {
					mycollapse.show()
					//Если надо закрывать соседние сабменю:
					// var opened_submenu = parentEl.parentElement.querySelector('.submenu.show');
					// if (opened_submenu) {
					//    new bootstrap.Collapse(opened_submenu);
					// }
				}
			}
		})
	})
})

// ЗАКРЫТЬ ОФФКАНВАС СВАЙПОМ

const OffcanvasEl = document.getElementById('Offcanvas')

if (OffcanvasEl) {
	let menuOffcanvas = new bootstrap.Offcanvas(OffcanvasEl)
	let startX, startY, endX, endY

	const swipeThreshold = 50

	OffcanvasEl.addEventListener('touchstart', e => {
		startX = e.touches[0].clientX
		startY = e.touches[0].clientY
	})

	OffcanvasEl.addEventListener('touchend', e => {
		endX = e.changedTouches[0].clientX
		endY = e.changedTouches[0].clientY

		const deltaX = endX - startX
		const deltaY = endY - startY

		if (Math.abs(deltaX) > swipeThreshold || Math.abs(deltaY) > swipeThreshold) {
			if (Math.abs(deltaX) > Math.abs(deltaY)) {
				if (deltaX > 0) {
					menuOffcanvas.hide()
				}
			}
		}
	})
}

//ПЛАГИН МАСОК ИНПУТОВ

import IMask from 'imask'

const phoneInput = document.querySelectorAll('.input-phone')
const textInput = document.querySelectorAll('.input-text')

if (phoneInput.length) {
	phoneInput.forEach(function (el) {
		var phoneMask

		el.addEventListener('focus', function () {
			phoneMask = IMask(el, {
				mask: '+{7} 000 000 00 00',
				lazy: false,
				placeholderChar: '*',
			})
		})

		el.addEventListener('blur', function () {
			phoneMask.updateOptions({
				mask: '+{7} 000 000 00 00',
				lazy: true,
			})
		})
	})
}

if (textInput.length) {
	textInput.forEach(function (el) {
		var textMask = IMask(el, {
			mask: /^[a-zа-яё\s]+$/iu,
		})
	})
}

// ВАЛИДАТОР ФОРМ
;(() => {
	'use strict'
	const forms = document.querySelectorAll('.needs-validation')
	Array.from(forms).forEach(form => {
		form.addEventListener(
			'submit',
			event => {
				if (!form.checkValidity()) {
					event.preventDefault()
					event.stopPropagation()
				}

				form.classList.add('was-validated')
			},
			false
		)
	})
})()

// ПРОСМОТРЩИК ФОТО

import { Fancybox } from '@fancyapps/ui'
Fancybox.bind('[data-fancybox]', {
	Toolbar: {
		display: {
			left: ['infobar'],
			right: ['zoomIn', 'thumbs', 'close'],
		},
	},

	l10n: {
		CLOSE: 'Закрыть',
		NEXT: 'Дальше',
		PREV: 'Назад',
		MODAL: 'Вы можете закрыть данное окно, нажав клавишу ESC',
		ERROR: 'Что-то пошло не так. Пожалуйста, повторите попытку позже',
		IMAGE_ERROR: 'Изображение не найдено',
		ELEMENT_NOT_FOUND: 'HTML элемент не найден',
		AJAX_NOT_FOUND: 'Ошибка загрузки AJAX : Не найдено',
		AJAX_FORBIDDEN: 'Ошибка загрузки AJAX : Запрещено',
		IFRAME_ERROR: 'Ошибка загрузки страницы',
		TOGGLE_ZOOM: 'Переключить уровень масштаба',
		TOGGLE_THUMBS: 'Переключить эскиз',
		TOGGLE_SLIDESHOW: 'Переключить презентацию',
		TOGGLE_FULLSCREEN: 'Переключить режим полного экрана',
		DOWNLOAD: 'Скачать',
		ZOOMIN: 'Увеличить',
		ZOOMOUT: 'Уменьшить',
	},
})

// АККОРДИОН

const accordionButton = document.querySelectorAll('.accordion-button')

if (accordionButton.length) {
	accordionButton.forEach(function (element) {
		element.addEventListener('click', function (e) {
			let parentEl = element.closest('.accordion-item')
			let submenuEl = parentEl.querySelector('.accordion-collapse')

			element.classList.toggle('collapsed')

			if (submenuEl) {
				e.preventDefault()
				let mycollapse = new bootstrap.Collapse(submenuEl)

				if (submenuEl.classList.contains('show')) {
					mycollapse.hide()
				} else {
					mycollapse.show()
				}
			}
		})
	})
}

// ОТКРЫТИЕ ДРОПДАУНА ПО ХОВЕРУ

const dropdowns = document.querySelectorAll('.dropdown')

if (dropdowns.length) {
	dropdowns.forEach(function (dropdown) {
		const dropdownToggle = new bootstrap.Dropdown(dropdown.querySelector('.dropdown-toggle'), {
			offset: [0, 0],
		})

		dropdown.addEventListener('mouseover', function (e) {
			dropdownToggle.show()
		})

		dropdown.addEventListener('mouseleave', function (e) {
			dropdownToggle.hide()
			dropdown.querySelector('.dropdown-toggle').blur()
		})
	})
}

// НАВБАР ВСЕГДА ВВЕРХУ ЭКРАНА

var topbar = document.querySelector('.topbar')
var navbar = document.querySelector('.navbar')
var navbarHeight = navbar.offsetHeight
var sticky = navbar.offsetTop

window.onscroll = function () {
	if (window.scrollY >= sticky) {
		navbar.classList.add('fixed-top')
		topbar.style.marginBottom = navbarHeight + 'px'
	} else {
		navbar.classList.remove('fixed-top')
		topbar.style.marginBottom = 0
	}
}

// ЗАПУСТИТЬ АНИМАЦИЮ

const sections = document.querySelectorAll('section')

sections.forEach(section => {
	const observer = new IntersectionObserver(
		entries => {
			entries.forEach((entry, index) => {
				if (entry.isIntersecting) {
					setTimeout(() => {
						entry.target.classList.add('play')
					}, index * 200)
				} else {
					entry.target.classList.remove('play')
				}
			})
		},
		{ threshold: 0.3 }
	)

	const animationElements = section.querySelectorAll('.anim')

	animationElements.forEach(element => {
		observer.observe(element)
	})
})

// СЛАЙДЕРЫ

import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

// СЛАЙДЕР ВРАЧЕЙ

const doctorsSliderContainers = document.querySelectorAll('.doctors-slider')

if (doctorsSliderContainers.length) {
	for (const sliderContainer of doctorsSliderContainers) {
		let slider = sliderContainer.querySelector('.swiper')
		let next = sliderContainer.querySelector('.sbn-1')
		let prev = sliderContainer.querySelector('.sbp-1')

		const swiperDoctors = new Swiper(slider, {
			modules: [Navigation],
			slidesPerView: 1.25,
			spaceBetween: 16,
			observer: true,
			loop: true,
			autoHeight: true,
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			breakpoints: {
				576: {
					slidesPerView: 2.001,
					spaceBetween: 16,
				},

				768: {
					slidesPerView: 3.001,
					spaceBetween: 16,
				},

				992: {
					slidesPerView: 4,
					spaceBetween: 16,
				},

				1200: {
					slidesPerView: 5,
					spaceBetween: 16,
				},

				1400: {
					slidesPerView: 5,
					spaceBetween: 24,
				},
			},
			// on: {
			// 	afterInit: function (swiperDoctors) {
			// 		var fancyAttrName = swiperDoctors.el.querySelector('.fbl').getAttribute('data-fancybox')
			// 		var dupedSwipers = swiperDoctors.el.querySelectorAll('.swiper-slide-duplicate')

			// 		for (var i = 0; i < dupedSwipers.length; i++) {
			// 			var dupedSwiperIndex = dupedSwipers[i].getAttribute('data-swiper-slide-index')

			// 			dupedSwipers[i].querySelector('.fbl').setAttribute('data-fancybox-index', dupedSwiperIndex)
			// 			dupedSwipers[i].querySelector('.fbl').removeAttribute('data-fancybox')
			// 			dupedSwipers[i].querySelector('.fbl').setAttribute('data-fancybox-trigger', fancyAttrName)
			// 		}
			// 	},
			// },
		})
	}
}

// СЛАЙДЕР УСЛУГ

const servicesSliderContainers = document.querySelectorAll('.services-slider')

if (servicesSliderContainers.length) {
	for (const sliderContainer of servicesSliderContainers) {
		let slider = sliderContainer.querySelector('.swiper')
		let next = sliderContainer.querySelector('.sbn-2')
		let prev = sliderContainer.querySelector('.sbp-2')

		new Swiper(slider, {
			modules: [Navigation],
			slidesPerView: 1.25,
			spaceBetween: 16,
			observer: true,
			loop: true,
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			breakpoints: {
				576: {
					slidesPerView: 2.001,
					spaceBetween: 16,
				},

				768: {
					slidesPerView: 3.001,
					spaceBetween: 16,
				},

				992: {
					slidesPerView: 4,
					spaceBetween: 16,
				},

				1200: {
					slidesPerView: 4,
					spaceBetween: 16,
				},

				1400: {
					slidesPerView: 4,
					spaceBetween: 24,
				},
			},
		})
	}
}

// ОДИНОЧНЫЙ СЛАЙДЕР

const singleSliderContainers = document.querySelectorAll('.single-slider')

if (singleSliderContainers.length) {
	for (const sliderContainer of singleSliderContainers) {
		let slider = sliderContainer.querySelector('.swiper')
		let next = sliderContainer.querySelector('.sbn-3')
		let prev = sliderContainer.querySelector('.sbp-3')

		new Swiper(slider, {
			modules: [Navigation, Pagination],
			slidesPerView: 1.25,
			spaceBetween: 16,
			observer: true,
			loop: true,
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
			breakpoints: {
				576: {
					slidesPerView: 2.001,
					spaceBetween: 16,
				},

				768: {
					slidesPerView: 3.001,
					spaceBetween: 16,
				},

				992: {
					slidesPerView: 4,
					spaceBetween: 16,
				},

				1200: {
					slidesPerView: 1,
					spaceBetween: 0,
				},

				1400: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
			},

			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '"></span>'
				},
			},
		})
	}
}
